p {
  text-align: center;
}
.buttonForm {
  margin-left: 2% !important;
  margin-right: 2% !important;
}
.buttonList {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}
.container {
  /* padding: 3%; */
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}
.checkboxGroup {
  display: flex;
  flex-direction: column;
  margin-left: 35%;
}
.checkbox {
  margin-bottom: 1%;
}

.titleSkill {
  color: #581f64 !important;
}

.line {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}
.container-feedback {
  border: 1px solid #0000001f;
}
.stars {
  color: #581f64 !important;
  border-color: #581f64 !important ;
}
.header {
  height: 120px;
  background-color: #581f64;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 0 30px;
}
.titleLhh {
  font: normal normal bold 40px/24px Arial;
  color: #fff;
}
.close-icon {
  color: #cccccc; /* couleur de base de l'icône */
}

.close-icon:hover {
  color: #581f64; /* couleur violette pour le hover et l'active */
  cursor: pointer;
}
.radios {
  color: "#581f64";
  margin-right: 10%;
}
