.title {
  color: #ffff !important;
  background-color: #581f64;
}
.contentDialog {
  background-color: #f0eded;
}
.cardEmail {
  background-color: #ffffff74;
  margin: 2em;
  padding: 2em;
}
