.App {
  font-family: sans-serif;
  text-align: center;
}
.square {
  width: 50%;
  height: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  text-align: center;
  padding: 2%;
}
.border {
  border: 1px solid #581f64;
  border-radius: 10%;
  background-color: #ffffff;
}
.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 700px;
  margin: 0 auto;
  margin-bottom: 3%;
}
.title {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-24) var(--unnamed-font-family-roboto);
  color: var(--unnamed-color-581f64);
  text-align: center;
  font: normal normal medium 14px/22px Roboto;
  letter-spacing: 0.12px;
  color: #e4c000;
  text-transform: uppercase;

  margin-top: 5%;
  opacity: 1;
}
.title2 {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-24) var(--unnamed-font-family-roboto);
  color: var(--unnamed-color-581f64);
  text-align: center;
  font: normal normal medium 14px/22px Roboto;
  letter-spacing: 0.12px;
  color: #e4c000 !important;
  text-transform: uppercase;

  margin-top: 5%;
  opacity: 1;
}
.tagl {
  border-radius: 16px;
  margin: 1%;
  background-color: #e4c000 !important;
  color: #581f64 !important;
  opacity: 1;
  font-size: 12px;
  letter-spacing: 0.22px !important;
}

.tagl.long {
  font-size: 10px;
}

.tagl.very-long {
  font-size: 8px;
}
.tagl.extrem-long {
  font-size: 6px;
}

.tag {
  border-radius: 16px;
  opacity: 1;
  margin: 1%;
  font: normal normal normal 12px/20px !important;
  letter-spacing: 0.22px !important;
  opacity: 1;
}
.yellow {
  background-color: #e4c000 !important;
  background: #e4c000 0% 0% no-repeat padding-box;
  color: #581f64 !important;
}
.green {
  background-color: #759016 !important;
  background: #759016 0% 0% no-repeat padding-box;
  color: #ffffff !important;
}
.title3 {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-24) var(--unnamed-font-family-roboto);
  color: var(--unnamed-color-581f64);
  text-align: center;
  font: normal normal medium 14px/22px Roboto;
  letter-spacing: 0.12px;
  color: #759016;
  text-transform: uppercase;

  margin-top: 5%;
  opacity: 1;
}
.titleRef {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-24) var(--unnamed-font-family-roboto);
  color: var(--unnamed-color-581f64);
  text-align: center;
  font: normal normal medium 16px/24px Roboto;
  letter-spacing: 0.12px;
  color: #581f64;
  text-transform: uppercase;
  opacity: 1;
  margin: 5%;
}
.listeRep {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-24) var(--unnamed-font-family-roboto);
  color: var(--unnamed-color-000000de);
  text-align: center;
  font: normal normal normal 12px/24px Roboto;
  letter-spacing: 0.37px;
  color: #000000de;
  opacity: 1;
}
.coss {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-24) var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0-5);
  text-align: left;
  font: normal normal normal 14px/22px Roboto;
  letter-spacing: 0.5px;
  color: #0080fd;
  opacity: 1;
}
.lhhFooter {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.28px;
  color: #581f64;
  opacity: 1;
}
.commentContainer {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  padding: 2%;
  border: 1px solid #0000001f;
  border-radius: 5%;
  background-color: #ffffff;
  margin: 2%;
}
.infoConsultant {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  padding: 2%;
  margin: 2%;
}
.comment {
  margin: 2% 0;
}
.commentTitle {
  letter-spacing: 0.12px;
  color: #581f64;
  text-transform: uppercase;
  opacity: 1;
}
.footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
}
.consultant {
  display: flex;
  flex-direction: column;
}
.closeIcon:hover {
  cursor: pointer;
}
